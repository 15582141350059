const fi = {
  language: 'FI',
  languageLong: 'Suomi',
  appTitle: 'Kyykkä-App',
  kyykka: 'Akateemisen kyykän MM-kilpailut',
  tournamentOffice: 'Kysymyksiä? Kisatoimisto on avoinna koko kyykkäpäivän ajan!',
  homeTitle: 'Tulospalvelu',
  manageMyTeams: 'Hallinnoi omia joukkueita',
  description: 'Seuraa Akateemisen kyykän MM-kilpailujen tuloksia reaaliajassa!',
  searchLabel: 'Hae joukkuetta',
  yle: 'Yleinen sarja',
  pro: 'Prosarja',
  frm: 'Firma-sarja',
  block: 'Lohko',
  round: 'Kierros',
  start_block: 'Alkulohko',
  continuation_block: 'Jatkolohko',
  quarter_final: 'Puolivälierä',
  semi_final: 'Semifinaali',
  bronze_game: 'Pronssiottelu',
  final: 'Finaali',
  team: 'Joukkue',
  field: 'Kenttä',
  scoreboard: 'Lohkopisteet - kierros',
  games: 'Pelit',
  gamesCount: 'peliä',
  gamesCountSingle: 'peli',
  finishedGames: 'Pelatut',
  ongoingGames: 'Käynnissä',
  upcomingGames: 'Tulevat',
  finishedNotFound: 'Ei pelattuja pelejä',
  ongoingNotFound: 'Ei käynnissä olevia pelejä',
  upcomingNotFound: 'Ei tulevia pelejä',
  wins: 'Voitot',
  ties: 'Tasan',
  losses: 'Häviöt',
  mean: 'KA',
  points: 'Pts',
  ongoingGame: 'Joukkue pelaa parhaillaan kentällä',
  teamSaved: 'Tallennettu omiin joukkueisiin',
  teamRemoved: 'Poistettu omista joukkueista',
  remove: 'Poista',
  myTeams: 'Omat joukkueet',
  noSavedTeams: 'Et ole vielä tallentanut joukkueita. Etsi ja lisää joukkueita haun kautta!',
  browseGames: 'Selaa sarjojen pelejä',
  nextGame: 'Seuraava peli kentällä',
  atTime: 'klo',
  map: 'Kartta',
  geolocationErrorType1: 'Sivustolla ei ole lupaa sijaintisi hakemiseen.',
  geolocationErrorType2: 'Sijaintisi hakemisessa tapahtui virhe.',
  contentNotFound: 'Hakemaasi sisältöä ei löytynyt.',
  newsListTitle: 'Ajankohtaista',
  tournamentNotReadyTitle: '!!! TURNAUKSEN AIKATAULUT TAI JOUKKUEET EIVÄT OLE AJAN TASALLA !!!',
  restaurants: "Ravintolat",
  openingTimes: "Aukioloajat",
  openingTimesCafe: "Kahvila",
  openingTimesBistro: "Anniskelu",
  openingTimesLunch: "Lounas ja Street Food",
  coffee: "Kahvi",
  tea: "Tee",
  hotJuice: "Kuuma mehu",
  donut: "Munkki",
  glutFreeDonut: "Gluteeniton munkki",
  candyBag: "Sotilaskoti -karkkipussi",
  grill: 'grilli',
  vendaceRove: "Muikkurove & valkosipulikastike",
  vendaceDish: "Muikkuannos",
  sausagePotatoes: "Makkaraperunat",
  largeSausagePotatoes: "Makkaraperunat jätti",
  vegetarianDishes: "Vegaaniannos, Falafel",
  pancake: "Lätty",
  rasberryDonut: "Vadelmamunkki",
  baoBunTeriyaki: "Bao Bun nyhtöpossu-teriayki-täytteellä 2 kpl",
  baoBunVoner: "Bao Bun vöner-täytteellä 2 kpl",
  muchosNachos: "Nachot (lisänä salsa tai cheddarjuustokastike 1 €)",
  fries: "Ranskalaiset",
  overTheTopFrenchFriesBacon: "Loaded fries (rapeaa pekonia, cheddarjuustokastiketta ja marinoitua punasipulia)",
  overTheTopFrenchFriesVoner: "Loaded fries (rapeaa vöner pekonia, aiolia ja marinoitua punasipulia)",
  food: "Ruoka",
  Linjasto: "Linjasto",
  kauraPunajuuri: "Kaura-punajuuripyöryköitä ja lime-chiligurttia sekä Papas Arrugadas-perunoita",
  jauhelihaPullat: "Jauhelihapyöryköitä ja pippurikastiketta sekä Papas Arrugadas-perunoita",
  bottlesCans: "Pullossa/tölkissä",
  peaSoup: "Hernekeitto",
  foodTicket: "Ruokalipukkeella",
  vegePeaSoup: "Kasvishernekeitto",
};

const en = {
  language: 'EN',
  languageLong: 'English',
  appTitle: 'Kyykkä-App',
  kyykka: 'World Championships of Academic kyykkä',
  tournamentOffice: 'Questions? The tournament office is open all day during the tournament!',
  homeTitle: 'Live Scores',
  manageMyTeams: 'Manage my teams',
  description: 'Follow the scores of Academic World Championship of Kyykkä in real time!',
  searchLabel: 'Search for a team',
  yle: 'Open league',
  pro: 'Pro league',
  frm: 'Company league',
  block: 'Group',
  round: 'Round',
  start_block: 'Group stage 1',
  continuation_block: 'Group stage 2',
  quarter_final: 'Quarterfinal',
  semi_final: 'Semifinal',
  bronze_game: 'Bronze game',
  final: 'Final',
  team: 'Team',
  field: 'Field',
  scoreboard: 'Group Scoreboard - round',
  games: 'Games',
  gamesCount: 'games',
  gamesCountSingle: 'game',
  finishedGames: 'Finished',
  ongoingGames: 'Ongoing',
  upcomingGames: 'Scheduled',
  finishedNotFound: 'No finished games',
  ongoingNotFound: 'No ongoing games',
  upcomingNotFound: 'No scheduled games',
  wins: 'Wins',
  ties: 'Ties',
  losses: 'Losses',
  mean: 'Mean',
  points: 'Pts',
  ongoingGame: 'The team is playing now on field',
  teamSaved: 'Saved to your teams',
  teamRemoved: 'Removed from your teams',
  remove: 'Remove',
  myTeams: 'My Teams',
  noSavedTeams: 'You haven\'t saved any teams yet. Try searching for a team and adding one!',
  browseGames: 'Browse games by leagues',
  nextGame: 'Next game on field',
  atTime: 'at',
  map: 'Map',
  geolocationErrorType1: "The page doesn't have permission to get your location",
  geolocationErrorType2: 'An error occurred while getting your location.',
  contentNotFound: 'The content you were accessing was not found.',
  newsListTitle: 'Recent news',
  tournamentNotReadyTitle: 'Note! Tournament schedule is not up to date!',
  restaurants: "Restaurants",
  openingTimes: "Opening hours",
  openingTimesCafe: "Cafe",
  openingTimesBistro: "Alcoholic Beverages",
  openingTimesLunch: "Lunch and Street Food",
  coffee: "Coffee",
  tea: "Tea",
  hotJuice: "Hot juice",
  donut: "Donut",
  glutFreeDonut: "Gluten-free donut",
  candyBag: "Sotilaskoti candy bag",
  grill: 'grill',
  vendaceDish: "Fried vendace & garlic sauce",
  sausagePotatoes: "Potato and sausage hash",
  largeSausagePotatoes: "Large potato and sausage hash",
  vegetarianDishes: "Vegan dish, fried falafel and fries",
  pancake: "Pancake",
  rasberryDonut: "Rasberry Donut",
  baoBunTeriyaki: "Bao Bun with pulled pork-teriayki-filling 2 pcs",
  baoBunVoner: "Bao Bun with vöner-filling 2 pcs",
  muchosNachos: "Nachos (add salsa or cheddarsauce for 1 €)",
  fries: "French Fries",
  overTheTopFrenchFriesBacon: "Loaded fries (crispy bacon, cheddarsauce and marinated red onion)",
  overTheTopFrenchFriesVoner: "Loaded fries (crispy vöner bacon, aioli and marinated red onion)",
  food: "Food",
  Linjasto: "From the buffet-line",
  kauraPunajuuri: "Oat-beetroot-balls with lime-chili-yogurt and Papas Arrugadas-potatoes",
  jauhelihaPullat: "Meatballs with pepper sauce and Papas Arrugadas-potatoes",
  peaSoup: "Pea soup",
  foodTicket: "With a food ticket",
  vegePeaSoup: "Vegan pea soup",
};

export const translations = {
  'fi-FI': fi, // The first option will be the default if the user has not set a language, see localData.js
  'en-US': en,
};

export const locales = Object.keys(translations);
export const localeNames = locales.map((key) => (
  { key, name: translations[key].language, nameLong: translations[key].languageLong }
));