/**
 * urls object keys
 * @typedef {('field'|'team'|'block'|'league'|'teams'|'myTeams'|'map')} UrlKey
 */
const urls = {
  field: {
    apiurl: '/field/',
    url: 'field',
  },
  team: {
    apiurl: '/team/',
    url: 'team',
  },
  block: {
    apiurl: '/block/',
    url: 'block',
  },
  league: {
    apiurl: '/league/',
    url: 'league',
  },
  teams: {
    apiurl: '/teams/all',
    url: '',
  },
  myTeams: {
    apiurl: '',
    url: 'my-teams',
  },
  pages: {
    apiurl: '',
    url: 'pages',
  },
  news: {
    apiurl: '',
    url: 'news',
  },
  entry: {
    apiurl: '',
    url: 'entry',
  },
  map: {
    apiurl: '',
    url: 'map',
  },
  restaurants: {
    apiurl: '',
    url: 'restaurants'
  }
};

const contentUrls = {
  news: '/contents?contentType=news&status=published&order[publishedAt]=desc&page=1&pageSize=3',
  pages: '/contents?contentType=pages&status=published',
  entries: '/contents?contentType=entries&status=published',
  ads: '/contents?contentType=ads&status=published',
};

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8010/proxy' : `/api/live`;
const contentBaseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8011/proxy/api' : `https://kyykka.fi/api`;
export const adsBaseUrl = 'https://kyykka.fi';

export const liveApiIdentifier = '/api/live';
export const contentApiIdentifier = 'api/contents';

/**
 * Forms an API url from the given parameters
 * @param {UrlKey} urlKey url object key
 * @param {(string|number)} value value for the query
 */
export const formApiUrl = (urlKey, value) => {
  if (!urls.hasOwnProperty(urlKey)) {
    console.error(`No key ${urlKey} found in urls object`);
  }

  return `${baseUrl}${urls[urlKey].apiurl}${value || ''}`;
};

/**
 * urls object keys
 * @typedef {('news'|'pages'|'ads')} ContentUrlKey
 */

/**
 * This hits Tulospalvelu in "megaapi.php"-file which is found in /live/-folder  
 * 
 * Forms an API url from the given parameters
 * @param {ContentUrlKey} urlKey url object key
 * @param {(string)} query extra query parameters
 */
export const formContentUrl = (urlKey, value) => {
  if (!contentUrls.hasOwnProperty(urlKey)) {
    console.error(`No key ${urlKey} found in contentUrls object`);
  }

  return `${contentBaseUrl}${contentUrls[urlKey]}${value || ''}`;
};

/**
 * Returns the frontend url path
 * @param {UrlKey} urlKey url object key
 */
export const getLinkUrl = (urlKey) => {
  if (!urls.hasOwnProperty(urlKey)) {
    console.error(`No key ${urlKey} found in urls object`);
  }

  return urls[urlKey].url;
};

/**
 * Returns the complete frontend url for urlKey and value
 * @param {UrlKey} urlKey url object key
 * @param {(string|number)} value value for the query
 */
export const formLinkUrl = (urlKey, value = undefined) => {
  const suffix = value !== undefined ? `/${value}` : '';

  return `/${getLinkUrl(urlKey)}${suffix}`;
};
